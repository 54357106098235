<template>
  <div class="card card-custom gutter-b">
    <div class="card-header">
      <h3 class="card-title">Detail Audit Trail</h3>
      <div class="my-auto">
        <button @click="goBack" class="btn btn-danger"><i class="fa fa-arrow-left"></i></button>
      </div>
    </div>
    <div class="card-body">
      <b-row>
        <b-col sm="2">
          <p style="font-weight: 600">Tanggal</p>
        </b-col>:<b-col sm="6">
          {{createdAt ? dateIndo(createdAt) : '-'}}
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="2">
          <p style="font-weight: 600">Username</p>
        </b-col>:<b-col sm="6">
          {{user.email}}
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="2">
          <p style="font-weight: 600">Role</p>
        </b-col>:<b-col sm="6">
          {{user.role.name}}
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="2">
          <p style="font-weight: 600">Menu</p>
        </b-col>:<b-col sm="6">
          {{entity}}
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="2">
          <p style="font-weight: 600">Tindakan</p>
        </b-col>:<b-col sm="6">
          {{action}}
        </b-col>
      </b-row>

      <hr>

      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Data Lama</th>
              <th>Data Baru</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td width="50%"><code style="display:block; white-space:pre-wrap">
                {{oldData ? oldData : '-'}}</code></td>
              <td width="50%"><code style="display:block; white-space:pre-wrap">
                {{newData ? newData : '-'}}</code></td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
import "devextreme/dist/css/dx.light.css";
import { mapGetters } from "vuex";
import moment from "moment";

let token = "";

export default {
  name: "AuditTrailDetail",
  data() {
    return {
      createdAt: null,
      entity: null,
      user: {
        direktorat: {},
        email: '',
        role: {},

      },
      action: null,
      oldData: {},
      newData: {},
    };
  },
  mounted: function() {
    this.$store.dispatch("token/getAccessToken").then(access_token => {
      token = access_token;
    })
    .then(() => {
      this.getDetailAudit();
    });
  },
  computed: {
    ...mapGetters({
      hasPermission: "token/hasPermission"
    }),
  },
  methods: {
    async getDetailAudit() {
      const id = this.$route.params.id;

      if (id) {
        this.acessDetailAudit(id)
          .then(data => {
            this.createdAt = data.created_at;
            this.entity = data.entity;
            this.user = data.user ? JSON.parse(data.user) : null;
            this.action = data.action;
            this.oldData = data.old_data ? JSON.stringify(JSON.parse(data.old_data), null, 2) : null;
            this.newData = data.new_data ? JSON.stringify(JSON.parse(data.new_data), null, 2) : null;
          });
      }
    },
    async acessDetailAudit(id) {
      return new Promise((resolve, reject) => {

        fetch(
          process.env.VUE_APP_URL_LOCAL + `/audit-trail/${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "bearer " + token
            }
          }
        )
          .then(response => response.json())
          .then(({ data }) => {
            resolve(data);
          })
          .catch(error => {
            reject(error);
          })
        })
    },
    dateIndo(value) {
      let date = new Date(value)

      return date ? moment(date).format('MMMM DD YYYY, h:mm:ss a') : null;
    },
    goBack() {
      this.$router.push({ path: `/audit-trail/table` });
    }
  }
};
</script>
